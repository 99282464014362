import React from "react"

// Components
import DownloadsSection from "components/downloads-sections"
import DownloadsList from "components/downloads-lists"

// Page Sections
import List from "./respi-list-webinars"

const Webinars = props => {
  return (
    <DownloadsSection
      id="respi-experts"
      viewAllowed={props.viewAllowed}
      data-filter="respiratory" data-filter-type="devices" data-section="experts"
    >
			<DownloadsList>
				<div className="block__content">
          <h3>What the experts are saying<span className="subtitle"> -- On-demand webinars</span></h3>
				</div>
        <List downloadsPage />
      </DownloadsList>
    </DownloadsSection>
  )
}

export default Webinars
